.info {
  position: absolute;
  padding: 40px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  min-height: 580px;
}

.login-heading {
  color: #fff !important;
}
.login-heading-text {
  color: #fff !important;
}

.btn-secondary {
  width: 100% !important;
  border: #fff 2px solid;
  background-color: transparent !important;
}
.info .btn-secondary {
  border: #fff 2px solid !important;
}
.btn-primary {
  border-radius: 50px !important;
  width: 100% !important;
  min-width: fit-content !important;
  /* height: 50px !important; */
  background-color: #f5786d !important;
  border: #f5786d;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-input-affix-wrapper > input.ant-input {
  padding-left: 10px !important;
}
.ant-input-affix-wrapper > input::placeholder {
  color: #252525;
}
.info-logo {
  position: absolute;
  left: 40px;
  top: 70px;
}
.selection-card {
  padding: 0px;
  border-radius: 30px;
}
.selection-card-new {
  background-color: #eaf4ff;
}
.selection-card-req {
  background-color: #ffeeed;
}
.btn-bg-blue {
  background-color: #124173 !important;
}
.modal-title {
  color: #f5786d !important;
}
input::placeholder {
  color: #959595 !important;
  font-weight: 500;
}
.ant-input {
  border-radius: 7px !important;
}
.new-account-model .ant-modal-footer {
  padding: 0 !important;
}
.ant-tabs.ant-tabs-top.ant-tabs-centered.setting-table {
  background: white;
  border-radius: 7px;
  padding: 20px;
}
.setting-table .ant-tabs-nav-list .ant-tabs-tab {
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
}
/* .tabnav .ant-tabs-nav-list .ant-tabs-tab{
    margin-left: 10px;
} */
.setting-table .ant-tabs-tab-active > .ant-tabs-tab-btn {
  color: #252525 !important;
}
.setting-table .ant-tabs-tab-btn:hover {
  color: #124173 !important;
}
.setting-table-tab .ant-tabs-nav-list .ant-tabs-tab {
  background: #eff4f9;
}
.setting-table .ant-tabs-tab-active {
  background-color: white !important;
}

.SearchButton {
  border: none;
  box-shadow: none;
}
.SearchButton span {
  font-size: 20px;
  color: #959595;
}

input:focus {
  box-shadow: none !important;
}
.ant-modal-content {
  border-radius: 7px !important;
}

.mobile-sign-up-text {
  width: 100%;
  display: none;
  justify-content: center;
  margin-top: 20px;
}

.sidebar-background {
  background-image: url("assets/Images/login_signup_background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

.account-suspend-container .ant-result-extra {
  display: flex;
  justify-content: center;
}

.login-form .ant-form-item-label label {
  font-size: 16px;
}

@media only screen and (max-width: 992px) {
  .mobile-sign-up-text {
    display: flex;
  }
  .login-form-container {
    width: 410px !important;
  }
}

@media only screen and (max-width: 576px) {
  .new-account-model {
    width: calc(100% - 50px) !important;
  }
}

@media only screen and (max-width: 420px) {
  .login-form-container {
    width: 100vw !important;
  }
}
