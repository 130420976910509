.language-option {
  display: flex;
  align-items: center;
  gap: 10px;
}

.language-option .nation-flag {
  font-size: 30px;
}

.language-selector .ant-select-selector {
  box-sizing: border-box !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
  height: 40px !important;
  padding: 8px 12px !important;
  background: none !important;
  border: 1px solid #464646 !important;
  border-radius: 8px !important;
}

.language-selector .ant-select-selection-item,
.language-selector .ant-select-selection-placeholder {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}
