.view-option {
  cursor: pointer;
  color: #f5786d !important;
}

.company-search-type .ant-select {
  height: 40px;
  width: 90px;
  /* dev:-other best option */
  /* width: fit-content; */
}

/* ------------------------------------------------ */
.tnc {
  height: calc(100vh - 175px);
}

.course-admin-tab .ant-tabs-nav {
  margin: 0px !important;
}

.course-admin-tab .ant-tabs-nav-list .ant-tabs-tab {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  background-color: #eff4f8 !important;
}

.course-admin-tab .ant-tabs-nav-list .ant-tabs-tab-active {
  background-color: white !important;
  border-bottom-color: white !important;
}

.course-admin-tab .ant-tabs-nav-list .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 4px !important;
}

@media only screen and (max-width: 420px) {
  .course-admin-tab .ant-tabs-nav-list .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 4px !important;
  }
}
/* ------------------------------------------------ */

.user-search-type .ant-select {
  height: 40px;
  width: 130px;
}

.plateform-selector .ant-select-selector {
  height: 34px !important;
  align-items: center;
}

.late-start-date-picker {
  height: 40px;
  border-radius: 8px;
  display: flex;
}

#new_account_late_start_date::placeholder,
.late-start-date-picker .ant-picker-input input {
  font-size: 16px;
}

.login-form .ant-form-item-label label {
  font-size: 16px;
}

.company-searchbox .ant-input {
  height: 40px;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  font-size: 16px;
}

.company-searchbox .ant-input-group-addon {
  display: block;
}
.company-searchbox .ant-input-search-button {
  height: 40px;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

/* -------------------------dashboards-------------------------- */

.dashboard-modal {
  max-width: 500px;
}

.dashboard-modal .ant-modal-header {
  background: #eff4f9;
  padding: 15px !important;
  border-radius: 8px 8px 0px 0px;
}

.dashboard-modal .ant-modal-close-icon {
  position: absolute;
  left: 5px;
  font-size: 22px;
  color: white;
}

/* for removing closable area from add button */
.dashboard-modal .ant-modal-close-x {
  position: absolute;
  left: 0px;
}

.dashboard-modal .ant-modal-body {
  max-height: 300px;
  overflow-y: scroll;
}

.dashboard-modal .dashboard-modal-header-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.dashboard-modal .ant-modal-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #252525;
}

.dashboard-modal .ant-modal-body {
  padding: 0px;
}

.dashboard-modal .dashboard-details-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  align-items: center;
  border-bottom: 1px solid #e2ebf3;
}

.dashboard-modal .dashboard-details-wrapper:last-child {
  border-bottom: none;
}

.dashboard-modal .dashboard-details {
  display: flex;
  gap: 10px;
  align-items: center;
}
.dashboard-modal .dashboard-name {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #124173;
  margin: 0px;
}
.dashboard-modal .default-tag {
  padding: 3px 12px;
  background: #e4f5f1;
  border-radius: 4px;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #25b291;
  margin: 0px;
}
.dashboard-modal .action-buttons {
  display: flex;
  gap: 24px;
}

.delete-dashboard-modal {
  max-width: 437px;
}

.delete-dashboard-modal .ant-modal-body {
  padding: 30px 38px;
}

.delete-dashboard-modal .delete-dashboard-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete-dashboard-modal-content .bulb-icon {
  height: 59px;
  width: 56px;
  margin-bottom: 22px;
}

.delete-dashboard-modal-content .warning-text {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #252525;
  margin-bottom: 6px;
}

.delete-dashboard-modal-content .accounts-text {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #124173;
  max-width: 300px;
  margin-bottom: 9px;
}

.delete-dashboard-modal-content .notify-text {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #959595;
  margin-bottom: 18px;
}

.delete-dashboard-modal-content .delete-dashboard-modal-buttons-container {
  display: flex;
  gap: 14px;
}

.dashboard-delete-loading-icon svg {
  height: 11px !important;
  width: 11px !important;
}

/* -------------------------manual billing-------------------------- */

.manual-billing-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
}

.manual-billing-label {
  color: #252525;
  font-family: Hellix;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.manual-billing-container .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f37064;
}

.manual-billing-container .ant-checkbox-input:hover + .ant-checkbox-inner,
.manual-billing-container .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: rgb(217, 217, 217);
}
