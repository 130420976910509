.workFlow .ant-layout , .ant-layout-header{
    background: transparent !important;
}

.workFlow .ant-layout-header{
    padding: 20px !important;
    height: auto !important;
}

.workFlow .ant-table-wrapper{
    width: 100%;
    padding: 0 20px;
}

.workFlow .site-layout-background{
    background: #dfe8f1  !important; 
    width: 280px !important;
    max-width: 280px !important;
    min-width: 280px !important;
    flex: 0 0 280px !important;
    border-radius: 10px 10px 0 0;
}

.workFlow .site-layout-background.ant-layout-sider-collapsed{
    width: 0 !important;
    max-width: 0 !important;
    min-width: 0 !important;
    flex: 0 0 0 !important;
}

.trigger2{
  background: #252525;
  padding: 12px 6px;
  color: white;
  border-radius: 7px 0 0 7px;
  margin-top: 51px;
  height: 38px;
}

.productSelect .ant-select-selector{
    background: white !important;
    border: none !important;
}
.productSelect .ant-select-selection-item , .productSelect .ant-select-arrow{
    color: black !important;
    font-weight: bold;
}

.workflow-sidebar .productSelect .ant-select-selection-item , .workflow-sidebar .productSelect .ant-select-arrow{
    color: white !important;
    font-weight: bold;
}

.workflow_header{
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: white;
    border-radius: 7px !important;
    min-height: 151px;
    gap: 16px !important;
}

.workflow_header .wrapper{
    display: block;
    position: absolute;
    z-index: 100;
    right: 0;
}

.workflow_header .ant-skeleton{
    line-height: 32px !important;
}

.listbtn{
    border-radius: 5px 0 0 5px;
}

.tablebtn{
    border-radius: 5px 5px 5px 5px;
    /* border-left: none; */
}

.listbtn , .tablebtn {
    background: transparent;
    border-color: #959595;
    width: 45px;
}

.listbtn img , .tablebtn img{
    height: 18px;
}

.searchNames .ant-input-affix-wrapper , .searchNames input {
    background: transparent !important;
    border-color: #959595 !important;
}

.searchNames input::placeholder , .workflow_header .ant-select-selection-placeholder , .workflow_header .ant-select-arrow{
    color: #252525 !important;
}

.sidebar_content .wrapper{
    border-bottom: 2px solid #EFF4F9;
    padding: 15px;
}
.sidebar_content .wrapper h3{
    font-weight: 600;
    letter-spacing: .75px;
}
.sidebar_content .wrapper .Box{
    background-color: white;
    padding: 10px 0 10px 15px;
    border-radius: 7px;
    margin-bottom: 15px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.sidebar_content .wrapper .Box h3{
    line-height: 18px;
    color: #124173;
    margin-bottom: 20px;
}
.sidebar_content .wrapper .Box p , .listView .boxHeader .flex p{
    color: #959595;
    font-style: italic;
    margin-bottom: 8px;
}
.sidebar_content .wrapper .Box .ant-tag {
    color: #252525;
    font-size: 14px;
    padding: 7px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.tableView .tag , .listView .tags{
    color: #252525;
    padding: 3px 6px;
    border-radius: 5px;
    margin-bottom: 5px;
}

.tableView  th.ant-table-cell{
    background-color: #E2EBF3 !important;
    color: #124173 !important;
    font-weight: 600;
    border: none !important;
}

.listView{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 7px;
    padding-bottom: 10px;
    margin-bottom: 30px;
    border-bottom: 2px solid #D7E1EB;
}
.listView .listHeader{
    width: 80%;
    background: #124173;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.21);
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 15px;
}   
.listView .listHeader h3{
    margin-bottom: 0;
    color: white;
    font-weight: 600;
}
.listView .listHeader .addT{
    background-color: #F37064;
    color: white;
    border-color: #F37064;
    font-size: 15px;
}
.listView .listContent{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.listView .listContent .box{
    z-index: 99;
    position: relative;
}
/* .listView .listContent svg circle{
    stroke: black;
    fill: white;
    strokeWidth: 2px;
} */
.listView .listContent .tag {
    text-align: center;
    background: #D7E1EB;
    color: #7F92A5;
    border-radius: 100px;
    padding: 5px 10px;
    font-size: 14px;
    margin: 10px 0;
    width: fit-content;
}
.listView .boxHeader{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.listView .boxHeader .flex{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.listView .boxHeader .flex p{
    margin-bottom: 0;
    margin-right: 10px;
}
.listView .ant-collapse-header{
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    color: #959595 !important;
    padding-left: 0 !important;
}
.listView .ant-collapse-header span{
    margin-left: 6px;
    margin-top: 2px;
}
.listView .ant-collapse-content-box{
    padding-top: 4px !important;
    padding-left: 0 !important;
}
.listView .ant-collapse-content-box p{
    margin-bottom: 0;
    font-size: 15px;
}
.editJourneyDrawer .ant-drawer-header{
    background-color: #EFF4F9 !important;
}