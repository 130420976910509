.d-flex,
.form-flex-wrapper .ant-row {
  display: flex !important;
}
.create-new-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  min-height: 250px;
  background: #ffffff;
  border-radius: 7px;
  padding: 10px;
}
.create-new-card .secondary-btn,
.template-body .secondary-btn,
.form-body .secondary-btn,
.options-form .secondary-btn {
  background: rgb(243, 112, 100);
  color: white;
  border-color: rgb(243, 112, 100);
  width: fit-content;
  margin: 0 auto;
}
.Form-empty-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  height: 550px;
  background: #ffffff;
  border-radius: 7px;
  padding: 10px;
}
.create-new-card.template .temp-name {
  font-size: 12px;
  color: #252525;
  margin-bottom: 0;
  line-height: 21px;
}
.create-new-card.template .subtitle {
  background: #eaf1f9;
  padding: 1px 3px;
  border-radius: 100px;
  font-weight: normal;
  font-size: 10px;
  line-height: 17px;
  padding: 0px 10px;
}
.create-new-card.template .ant-page-header-heading-title {
  display: flex;
  flex-direction: column;
  line-height: 3px;
}
.create-new-card.template .ant-page-header {
  padding: 0;
}

.template-body {
  height: 80%;
  margin-top: 5px;
  position: relative;
  cursor: pointer;
}
.noneClickable::before {
  content: "";
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.template-body .ant-form-item-label {
  padding: 0;
  line-height: 12px !important;
}
.template-body .ant-form-item-label label {
  font-size: 10px;
}
.template-body .ant-form-item-control-input {
  min-height: auto;
}
.template-body .ant-form-item {
  margin-bottom: 5px;
}
.template-body input {
  border-radius: 3px !important;
}
.edit-sidebar .ant-tabs-nav {
  background: #cde1f3;
  margin-bottom: 0;
}
.edit-sidebar .ant-tabs-tab {
  width: 70px;
  display: flex;
  justify-content: center;
}
.edit-sidebar .ant-tabs-tab > .ant-tabs-tab-btn {
  color: #252525 !important;
}
.edit-sidebar .ant-tabs-tab-active-active > .ant-tabs-tab-btn {
  color: #124173 !important;
}
.edit-sidebar .ant-tabs-ink-bar {
  background: #252525 !important;
}
.edit-sidebar span.anticon.anticon {
  font-size: 16px;
}
.edit-sidebar .ant-tabs-content-holder {
  padding: 0 10px;
  min-height: 63vh;
  overflow: scroll;
}

.layouts {
  background: white;
  border-radius: 7px;
  padding: 10px;
  margin-bottom: 10px;
  border: 2px solid transparent;
}
.layouts.active {
  border-color: #124173;
}

.form-body {
  min-height: 75vh;
  background: white;
  padding: 20px;
}
.form-body .form {
  height: 100%;
}

.form-body .ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  white-space: nowrap;
}

.component-wrapper .alignment-opt .ant-radio-button-wrapper-checked {
  background: #124173;
}

.component-wrapper .alignment-opt .ant-radio-button-wrapper-checked span {
  color: white !important;
}

.component-wrapper .form-element-select .ant-radio-button-wrapper {
  margin: 0 10px 0 0;
  border: 1px solid #124173;
  border-radius: 7px;
}

.component-wrapper .form-element-select .ant-radio-button-wrapper-checked {
  box-shadow: 0 0 0 3px #124173;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  display: none !important;
}

.image-component {
  height: 400px;
}
.form-grid .ant-row {
  width: 100%;
  display: grid;
  grid-template-columns: 40% 60%;
  margin-bottom: 11px;
}
.options-form .ant-form-item {
  grid-template-columns: 100% !important;
}
.input-component .ant-form-item .ant-form-item-label {
  display: flex;
}
.input-component .attributes {
  margin-top: 20px;
}
.input-component input,
.input-component .ant-select-selector,
.input-component .ant-input {
  background: #eaf1f9 !important;
  border: 0 !important;
}

.form-flex-wrapper .alignment-opt {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.input-component .options-form .ant-form-item {
  margin-bottom: 0;
}
.upload-doc-btn .ant-upload {
  width: 100%;
}

.settings-opt .ant-collapse-header,
.settings-opt .ant-collapse-item,
.settings-opt .ant-collapse,
.settings-opt .ant-collapse-content {
  background: transparent !important;
  border: none !important;
}
.settings-opt .ant-collapse-content {
  border-bottom: 1px solid #252525 !important;
  border-radius: 0 !important;
}
.settings-opt .ant-collapse-header {
  border-bottom: 1px solid #252525 !important;
  border-radius: 0 !important;
}
.settings-opt .ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
  border-bottom: none !important;
}
.settings-opt .ant-collapse-header {
  color: #252525 !important;
  font-weight: 600;
}
.settings-opt .ant-collapse-content-box {
  padding: 0 10px 20px !important;
}

.white-box {
  background: white;
  border-radius: 7px;
  width: 100%;
  overflow: hidden;
}

.white-box .ant-upload {
  width: 100%;
}
.typography-form.form-grid .ant-row {
  width: 100%;
  display: grid;
  grid-template-columns: 27% 73%;
  margin-bottom: 11px;
}
.typography-form.full-W .ant-form-item {
  grid-template-columns: 100%;
}
.typography-form .ant-form-item-control-input-content {
  display: flex;
  justify-content: flex-start;
}
.typography-form span.anticon.anticon {
  font-size: 14px;
}
.typography-form .ant-select-selector {
  border-radius: 0 !important;
  background-color: transparent !important;
  border: 1px solid #464646 !important;
}
.typography-form .ant-select {
  width: 33%;
}
.typography-form .ant-form-item .ant-form-item-label {
  display: flex;
}
.typography-form .Opt-1 .ant-select-selector {
  border-radius: 7px 0 0 7px !important;
}
.typography-form .Opt-3 .ant-select-selector {
  border-radius: 0 7px 7px 0 !important;
}
.typography-form .Opt-1.round .ant-select-selector {
  border-radius: 7px !important;
}
.preview-box span.anticon.anticon {
  font-size: 16px;
}

.google-captcha-form .ant-input {
  background-color: transparent !important;
  border: 1px solid #464646 !important;
}
.google-captcha-form .ant-form-item {
  margin-bottom: 10px !important;
}
.edit-sidebar .ant-checkbox-inner {
  background: transparent;
  border-color: #464646;
}
.edit-sidebar .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #464646;
}

.site-page-header-ghost-wrapper .title .ant-typography-edit {
  color: #124173;
}
.ant-popover-inner {
  border-radius: 7px !important;
  overflow-x: hidden;
}

.ant-popover-inner-content {
  padding: 0;
}
.ant-popover-placement-bottomRight .ant-popover-inner-content .publish {
  overflow: scroll;
  height: 80vh;
}
.input-group input:nth-child(1) {
  border-radius: 7px 0 0 7px !important;
  background: #eaf1f9 !important;
  color: black !important;
  border: 1px solid #cde1f3 !important;
  height: 40px;
}
.input-group input:nth-child(2) {
  border-radius: 0px 7px 7px 0px !important;
  background: #eaf1f9;
  color: black;
  border: 1px solid #cde1f3 !important;
  height: 40px;
}
.publish .wrapper-box {
  background: white;
  padding: 20px 10px;
  margin: 20px 10px 10px !important;
  border: 1px solid #124173;
  box-shadow: 0px 1px 10px rgba(18, 65, 115, 0.2);
  border-radius: 4px;
}
.publish textarea {
  background: #eaf1f9;
  margin-top: 20px;
  border: 1px solid #cde1f3 !important;
}
.logo-box .ant-upload-list.ant-upload-list-text {
  display: none !important;
}

.dynamic-form .dynamic-form-item .ant-form-item-label label {
  width: 95% !important;
}

.preview-modal .ant-modal-body {
  padding: 0 !important;
  height: 90vh;
  overflow-y: scroll;
  border-radius: 0 !important;
  position: relative;
  overflow-x: hidden !important;
}
.ant-page-header-heading-extra button .text {
  display: none;
}

.react-colorful__saturation {
  border: none !important;
  overflow: hidden !important;
}

.ant-form-item-control-input-content .ant-input-number {
  width: 100% !important;
  border-radius: 7px;
}

.ant-input-number .ant-input-number-handler-wrap {
  background-color: transparent !important;
}

.ant-tabs-nav {
  margin-bottom: 1px !important;
}

.anticon-mobile,
.anticon-laptop {
  margin: 0px !important;
}

.preview-tabs .ant-tabs-nav {
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9;
  width: 90%;
  background: white;
  width: 97.8%;
  border-radius: 7px 7px 0 0;
}

.preview-tabs .ant-tabs-content-holder {
  margin-top: 47px;
}
.form-delete-modal {
  font-size: 16px !important;
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete-modal-text {
  margin-top: 20px;
}

.delete-modal-button-container,
.publish-modal-button-container,
.unpublish-modal-button-container {
  display: flex;
  gap: 20px;
}

.delete-modal-denied-button,
.publish-modal-denied-button,
.unpublish-modal-denied-button {
  border: 1px solid #252525;
}

.tnc-label {
  display: flex;
  align-items: center;
}

.tnc-label label {
  display: block;
  width: fit-content;
  white-space: break-spaces;
  min-height: 28px;
  height: fit-content;
  text-align: start;
}

.colorpicker-input {
  width: 100%;
  height: 100%;
  border: none;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  margin-bottom: 0px;
}

.form-heading {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.form-heading p,
.form-sub-heading p,
.form-company-text p {
  margin-bottom: 0px !important;
}

#qr-code2 {
  position: fixed;
  top: 200vh;
  left: 200vw;
  visibility: hidden;
}

.site-page-header-responsive .ant-page-header-heading-extra {
  display: flex;
  align-items: center;
}

.site-page-header-responsive .ant-switch-checked {
  background-color: #124173;
}

.form-save {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 18px !important;
}

.form-element-order .ant-form-item-control-input-content {
  display: flex;
  gap: 10px;
}

.preventNavModal .ant-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.template-form-heading,
.template-form-heading span,
.template-form-heading p,
.template-form-heading h1,
.template-form-heading h2,
.template-form-heading h3,
.template-form-heading h4,
.template-form-heading h5,
.template-form-heading h6 {
  font-size: 14px !important;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 0px;
}

/* --------------------------------  hide form  ---------------------------------------- */

.hide-form-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.permanent-hide-form {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.template-form-company-text,
.template-form-company-text span,
.template-form-company-text p,
.template-form-company-text h1,
.template-form-company-text h2,
.template-form-company-text h3,
.template-form-company-text h4,
.template-form-company-text h5,
.template-form-company-text h6 {
  font-size: 8.5px !important;
  overflow: hidden;
  margin-bottom: 0px;
  max-height: 110px;
}

/* --------------------------------  media queries  ---------------------------------------- */

@media only screen and (min-width: 992px) and (max-width: 1600px) {
  .typography-form.form-grid .ant-row {
    grid-template-columns: 100%;
  }
}

@media only screen and (min-width: 1370px) {
  .component-main-buttons-container {
    display: flex !important;
    column-gap: 5px;
    row-gap: 10px;
    justify-content: space-around;
  }
  .component-main-button-wrapper {
    width: 49% !important;
    padding-left: 0px;
    padding-right: 0px;
  }
  .component-main-buttons {
    width: 100% !important;
    display: flex !important;
    justify-content: flex-start !important;
    flex-direction: row !important;
    align-items: center !important;
    gap: 5px !important;
    padding: 12px 5px;
    min-width: fit-content !important;
    height: 40px !important;
    border-radius: 8px !important;
    font-family: "Hellix" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
  }
}

@media only screen and (max-width: 1370px) {
  .component-main-button-wrapper {
    width: 100% !important;
  }
  .component-main-buttons {
    width: 100% !important;
    display: flex;
    justify-content: flex-start !important;
    align-items: center !important;
    padding: 12px 5px !important;
    gap: 5px !important;
    min-width: fit-content !important;
    height: 40px !important;
    border-radius: 8px !important;
    font-family: "Hellix" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
  }
}

@media only screen and (max-width: 992px) {
  .component-main-button-wrapper {
    width: 24% !important;
  }
  .component-main-buttons {
    gap: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .component-main-button-wrapper {
    width: 49% !important;
  }
}

@media only screen and (max-width: 576px) {
  .typography-form.form-grid .ant-row {
    grid-template-columns: 100%;
  }

  .publish {
    width: 300px !important;
  }

  .publish .mt-3 {
    margin-top: 5px;
  }

  .qr-container {
    margin-left: -5px;
    padding-left: 0 !important;
  }
  .component-main-button-wrapper {
    width: 100% !important;
  }
}

.tree-select .ant-select-selector {
  background-color: transparent !important;
  border: none !important;
}
