@import "~antd/dist/antd.css";

.input-text-box {
  padding: 10px !important;
}
.ant-input-affix-wrapper {
  border-radius: 7px !important;
}
.ant-input-affix-wrapper-lg {
  padding: 11px 15px;
}
.ant-layout {
  background-color: #fff !important;
}
.blustream-sidebar-menu .ant-menu-item.ant-menu-item-selected {
  background: #053262 !important;
  border-radius: 30px !important;
  position: relative;
}
.ant-menu-item.ant-menu-item-selected::after {
  content: "";
  width: 10px;
  height: 10px;
  background: #f5786d;
  border-radius: 50%;
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.ant-menu-sub .ant-menu-item-selected::after {
  background: transparent;
  content: "\2713";
  width: 22px;
  height: 100%;
  border-radius: 0;
  text-align: center;
}
.contactBox .anticon svg {
  height: 15px;
}
.ant-menu-dark .ant-menu-item > span > a,
.-title-content {
  font-size: 18px;
}
.ant-menu-sub .ant-menu-item {
  padding-left: 60px !important;
}

.back-to-admin-link {
  border-radius: 7px;
  padding: 10px;
  text-align: center;
}
.dropdown-menu.ant-menu-item-selected {
  background-color: #fff !important;
  border-radius: 0px !important;
}

.wrapperBox {
  position: relative;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
  height: calc(100vh - 64px);
}

iframe .narration-chart {
  display: flex !important;
  justify-content: center !important;
}

.add-btn:hover,
.add-btn:focus,
.add-btn:active {
  background: transparent !important;
}

.secret-key-value {
  color: #959595;
}

.mobile-login-text {
  width: 100%;
  display: none;
  justify-content: center;
  margin-top: 20px;
}

.journey-table td {
  padding: 0 !important;
}
.journey-table .p-16 {
  padding: 16px !important;
}
.journey-table .name-wrapper {
  background-color: #e6edf4;
}
.journey-table .d-block {
  display: block;
}

.journey-test-modal {
  max-width: 438px !important;
}

.journey-test-modal .ant-modal-header {
  border-bottom: none !important;
  border-radius: 12px !important;
  padding: 20px !important;
}

.journey-test-modal .ant-modal-body {
  padding: 0px 20px !important;
}

.journey-test-modal .ant-input-affix-wrapper {
  height: 42px !important;
}

.journey-test-modal .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}

.journey-test-modal .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #124173;
}

.journey-test-modal .ant-checkbox-inner {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 1.5px solid #959595;
}

.journey-test-modal .ant-checkbox + span {
  font-size: 18px;
  line-height: 22px;
  color: #252525;
  position: relative;
  bottom: 2px;
}
.journey-test-modal .ant-checkbox-checked .ant-checkbox-inner::after {
  left: 6px !important;
}

.journey-test-modal .ant-checkbox-checked::after {
  border: none !important;
  outline: none !important;
}

.journey-test-modal .search-icon {
  font-size: 18px;
  line-height: 22px;
  color: #959595;
}

.journey-test-modal .ant-modal-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: none !important;
  background-color: #f5f5f5;
  padding: 12px 26px 16px 20px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.journey-test-modal .ant-modal-content {
  border-radius: 12px !important;
}

@media only screen and (max-width: 992px) {
  .mobile-login-text {
    display: flex;
  }
}
