.circle{
    width: 18px;
    height: 18px;
    border-radius: 100px;
    border: 1px solid #959595;
}
.site-collapse-custom-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
    vertical-align: -4px !important;
}
.site-collapse-custom-collapse > .ant-collapse-item > .ant-collapse-header {
    border-bottom: 1px solid #F0F0F0 !important;
    line-height: 2.5715 !important;
}
.site-collapse-custom-collapse .site-collapse-custom-panel{
    margin-bottom: 10px;
    background: white;
    border-radius: 10px !important;
    border: none;
}
.site-collapse-custom-collapse.ant-collapse-borderless{
    background-color: transparent !important;
}
.site-collapse-custom-collapse .message label{
    width: 100%;
}

.triggerTypes.ant-menu-inline .ant-menu-item:not(:last-child){
    margin-bottom: 0;
}
.triggerTypes.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: transparent !important;
    color: black;
}
.triggerTypes .ant-menu-item.ant-menu-item-selected::after{
    background-color: #F5786D !important;
    border-right: none !important;
}