.ant-descriptions-view {
  border-radius: 7px !important;
}
.desc_item {
  display: flex;
  justify-content: space-between;
}

.ant-modal-body {
  border-radius: 7px !important;
}
.invite-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.invite-modal .ant-modal-body {
  padding-bottom: 0px !important;
}
.invite-modal-body > * {
  padding: 5px 0px;
}
.ant-select-selector {
  border-radius: 7px !important;
}
.ant-select-focused {
  outline: none !important;
  border: none !important;
}
.ant-input-group-addon {
  display: none;
}

.shopify-user-form .ant-input-wrapper {
  display: flex;
}

.shopify-user-form .ant-input-group-addon {
  display: flex;
  width: 70px;
  height: 44px;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  align-items: center;
}
