
.journey-table .edit-journey-wrapper {
    background-color: #e6edf4;
    padding: 11px !important;
    display: flex;
    justify-content: flex-end;
}
/* Drawer Action Button css Cancel/Save*/
.action-buttons-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto;
    border-top: 1px solid #e9e9e9;
    font-size: 18px;
    padding: 10px 16px;
    background: #EFF4F9;
    text-align: right;
    display: flex;
}

/*Begin of Edit Journey*/

.journey-state-switcher {
    display: flex;
    border: 1px solid #cccccc;
    border-radius: 10px;
    overflow: hidden;
    background: #ffffff;
}

.journey-state-switcher .journey-state-button {
    appearance: none;
    color: black;
    flex: 1;
    padding: 16px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative;
    border: none !important;
    box-shadow: none !important;
    margin-right: 4px;

    /* support multiple browsers*/
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -ms-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
}

.journey-state-switcher .journey-state-button:last-child {
    margin-right: 0;
}

.journey-state-switcher .journey-state-button:not(:last-child)::after {
    content: "";
    position: absolute;
    right: -3px;
    top: 25%;
    bottom: 25%;
    width: 1px;
    background-color: #cccccc;
}
.journey-state-switcher .journey-state-button.active::after {
    right: -3px;
}
.journey-state-switcher .journey-state-button:hover:not(.active)::after {
    right: -3px;
}
.journey-state-switcher .journey-state-button:hover:not(.active) {
    background-color: #cccccc;
    color: #ffffff !important;
    z-index: 2;
}
.journey-state-switcher .journey-state-button:focus{
    outline:none;
}

.journey-state-switcher {
    margin-right: -4px;
}

/*End of Edit Journey Drawer */