body {
  background: rgb(239, 244, 249);;
}
/* Branding Preview CSS */
.app-container {
  font-family: sans-serif;
  width: 320px;
  margin: 20px auto;
  border: 10px solid #000;
  border-radius: 40px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  position: relative;
  background-color: #fff;
}

.screen-container {
  display: flex;
  flex-direction: column;
  height: 600px;
}

.header {
  display: flex;
  justify-content:center;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #ffffff;
}

.header-icon {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}

.header-icon img {
  height: 56px;
  width: 56px;
}

.hamburger-btn {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  font-size: 32px;
  font-weight: 500;
  cursor: pointer;
  
}

.chat-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.header-title {
  margin: 0;
  font-size: 1rem;
}

.back-btn {
  position: absolute;
  left: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.chat-container {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  /* background-color: #fafafa; */
}

.incoming-message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.incoming-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 8px;
}

.bubble-left {
  margin-top: 1.5px;
  background-color: #ddd;
  padding: 8px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  max-width: 78%;
}
.bubble-left  P {
  margin: 0;
  text-align: start;
}

.bubble-right {
  background-color: #cce5ff;
  padding: 8px;
  border-radius: 10px;
  margin-bottom: 10px;
  max-width: 78%;
  min-width: 50px;
  align-self: flex-start;
  display: inline-block;
  width: auto;
  position: relative;
  left: 220px;
}

.bubble-right p {
  margin: 0;
  text-align: center;
}

.chat-input-wrapper {
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 8px;
  background-color: #fff;
  border-top: 1px solid #ccc;
}

.circle-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-bubble {
  flex: 1;
  background-color: #f2f2f2;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 0 12px;
}

.chat-input {
  flex: 1;
  border: none;
  outline: none;
  background: transparent;
  font-size: 1rem;
}

.settings-container {
  flex: 1;
  overflow-y: auto;
  background-color: #fafafa;
}

.settings-item {
  padding: 15px 20px; 
  border-bottom: 1px solid #E0E0E0; 
  display: block;
  text-decoration: none;
  
}

.settings-item-toggle span, .settings-item-bold{
  font-weight: 600;
  color: #000;
  font-size: 14px;
}

.settings-item-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Toggle switch styles */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 22px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.switch input:checked + .slider {
  background-color: #2C3E50;
}

.switch input:checked + .slider:before {
  transform: translateX(18px);
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

.logout-btn,
.add-home-btn {
  width: 100%;
  margin: 1px auto;
  background-color: #ccc;
  color: #000;
  border-radius: 80px;
  font-weight: 600;
  height: 40px;
}

.logout-btn,
.add-home-btn:first-child {
  margin-left: 0;
}

.logout-btn,
.add-home-btn:last-child {
  margin-right: 0;
}

.branding-editor-main-container {
 border-radius: 12px;
 display: flex;
 flex: 1;
 margin-right: 0;
 background: rgb(239, 244, 249);
}

.branding-editor-main-container .editor-container {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 0 0px rgba(0, 0, 0, 0.1);
}

.ant-collapse-header {
  background-color: #e2ebf3 !important;
  border: none !important;
  position: relative;
  padding: 0;
}

.ant-collapse-item {
  border: none !important;
  background: #e2ebf3 !important;
  position: relative;
}

.ant-collapse-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 10px; 
  right: 10px; 
  height: 1px; 
  background-color: #000; 
}

.ant-collapse-item-active .ant-collapse-content {
  background-color: #e2ebf3 !important;
  border: none;
}

.ant-collapse-content-box {
  padding: 0 10px 20px !important;
}
.ant-collapse-item {
 padding-bottom: 0 !important;
}
.ant-collapse-item-active .ant-collapse-header,
.ant-collapse-item-active .ant-collapse-content {
  background-color: #e2ebf3  !important;
}

.ant-row {
  padding: 0 !important;
  margin-top: 0px !important;
}

.ant-collapse-content-box p {
  padding: 0 10px 20px !important;  
  color: #464646 !important;  
  font-size: 14px !important;
  margin: 0 !important;
  font-weight: 500 !important;
  font-family: "Hellix", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
}

@media (max-width: 600px) {
  .logout-btn,
  .add-home-btn {
    font-size: 14px;
  }
}

@media (max-width: 400px) {

  .logout-btn,
  .add-home-btn {
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  .logout-btn,
  .add-home-btn {
    font-size: 12px;
  }
}