@media only screen and (max-width: 768px) {
  .form-row-container {
    justify-content: center !important;
    padding: 10px 0 0 0 !important;
  }
  .form-col-two-container {
    padding: 0px !important;
  }
  .form-col-two-wrapper {
    justify-content: center !important;
  }
}
.poweredby-logo {
  height: "25px";
  width: "25%";
  position: "absolute";
  bottom: "-350px";
  margin-left: "420px";
  cursor: "pointer";
}

/* form error page Css */

.form-error-page {
  width: 100vw;
  height: 100vh;
  background-color: #eff4f9;
}

.form-error-page-content {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-error-page-title {
  margin-top: 44px;
  margin-bottom: 20px;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 24px;
  text-align: center;
  color: #252525;
}

.form-error-page-text {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #464646;
  max-width: 614px;
  text-align: center;
}
