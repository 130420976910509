@import "~antd/dist/antd.dark.css";
@import "~antd/dist/antd.compact.css";

body {
  margin: 0;
  font-family: "Hellix", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@font-face {
  font-family: "Hellix";
  src: url("./assets/fonts/Hellix/Hellix-Regular.woff");
  src: url("./assets/fonts/Hellix/Hellix-Bold.woff");
  src: url("./assets/fonts/Hellix/Hellix-SemiBold.woff");
  src: url("./assets/fonts/Hellix/Hellix-Medium.woff");
  src: url("./assets/fonts/Hellix/Hellix-Regular.woff2");
  src: url("./assets/fonts/Hellix/Hellix-Bold.woff2");
  src: url("./assets/fonts/Hellix/Hellix-SemiBold.woff2");
  src: url("./assets/fonts/Hellix/Hellix-Medium.woff2");
}

/* loader */

.loading {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 50px;
  height: 50px;
  margin-top: -0.5em;

  border: 2px solid #f5786d;
  border-radius: 100%;
  border-bottom-color: transparent;
  -webkit-animation: spinner 1s linear 0s infinite;
  animation: spinner 1s linear 0s infinite;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.single-element-loader {
  position: absolute;
  z-index: 999;
  overflow: show;
  /* margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; */
  width: 100%;
  height: 100%;
}

/* Transparent Overlay */
.single-element-loader:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

/* :not(:required) hides these rules from IE9 and below */
.single-element-loader:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.single-element-loader:not(:required):after {
  content: "";
  display: block;
  position: absolute;
  font-size: 10px;
  width: 25px;
  height: 25px;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  margin-top: -0.5em;

  border: 2px solid #f5786d;
  border-radius: 100%;
  border-bottom-color: transparent;
  -webkit-animation: spinner 1s linear 0s infinite;
  animation: spinner 1s linear 0s infinite;
}

.ant-tabs-tab-active > .ant-tabs-tab-btn {
  color: #f5786d !important;
}
.ant-tabs-tab-btn:hover {
  color: #f5786d !important;
}
.ant-tabs-ink-bar {
  position: absolute;
  background: #f5786d !important;
  pointer-events: none;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 3px !important;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: transparent !important;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 calc(50% - 35px / 2) !important;
}

.ant-spin-dot-item {
  background-color: #f5786d !important;
}
.ant-btn-primary {
  border-color: #f5786d !important;
  background-color: #f5786d !important;
}
.ant-btn-primary[disabled] {
  color: rgba(255, 255, 255, 0.678) !important;
}
.nextbtn {
  display: block;
  width: 24px;
  height: 24px;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
  margin: 0px 7px;
}

.p-20 {
  padding: 20px;
}

.ant-skeleton-button {
  border-radius: 7px !important;
}

.all-contact-heading {
  margin-bottom: 0 !important;
}

.flexbox-container {
  display: flex;
}

.company-description .ant-typography-copy {
  display: flex !important;
  align-items: center !important;
}

.ant-select-tree
  .ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper:hover,
.ant-select-tree
  .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: transparent !important;
}

.standard-button,
.ant-btn[disabled] {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 12px 20px !important;
  gap: 10px !important;
  min-width: fit-content !important;
  height: 40px !important;
  border-radius: 8px !important;
  font-family: "Hellix" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px !important;
}

.primary-orange-button {
  border-color: #f37064 !important;
  background: #f37064 !important;
  color: #ffffff !important;
}

.primary-orange-button:hover,
.primary-orange-button:focus {
  color: white !important;
  background: #f37064 !important;
  border-color: #f37064 !important;
}

.secondary-orange-button {
  border: 1px solid #f37064 !important;
  color: #f37064 !important;
  background: transparent !important;
}

.secondary-orange-button:hover,
.secondary-orange-button:focus {
  color: #f37064 !important;
  border-color: #f37064 !important;
}

.tertiary-button {
  border: 1px solid #959595 !important;
  color: #959595 !important;
  background: none !important;
}

.tertiary-button:hover,
.tertiary-button:focus {
  color: #959595 !important;
  border-color: #959595 !important;
  background: none !important;
}

.primary-dark-blue-button {
  border-color: #124173 !important;
  background: #124173 !important;
  color: #ffffff !important;
}

.primary-dark-blue-button:hover,
.primary-dark-blue-button:focus {
  color: white !important;
  background: #124173 !important;
  border-color: #124173 !important;
}

.secondary-dark-blue-button {
  border: 1px solid #124173 !important;
  color: #124173 !important;
}

.secondary-dark-blue-button:hover,
.secondary-dark-blue-button:focus {
  color: #124173 !important;
  border-color: #124173 !important;
}

.primary-light-blue-button {
  border-color: #3b84d1 !important;
  background: #3b84d1 !important;
  color: #ffffff !important;
}

.primary-light-blue-button:hover,
.primary-light-blue-button:focus {
  color: white !important;
  background: #3b84d1 !important;
  border-color: #3b84d1 !important;
}

.ant-btn[disabled],
.ant-btn[disabled]:hover {
  border: 1px solid #c4c4c4 !important;
  background: #c4c4c4 !important;
  color: #ffffff !important;
}

.section-select-plan-btn .ant-tooltip-disabled-compatible-wrapper,
.plan-details .ant-tooltip-disabled-compatible-wrapper {
  background: none !important;
}

.standard-input,
.standard-searchbox .ant-input-affix-wrapper {
  box-sizing: border-box !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center !important;
  padding: 10px 12px !important;
  height: 40px !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 8px !important;
  font-family: "Hellix" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #252525;
}

.standard-input input {
  font-family: "Hellix" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #252525;
}

.standard-searchbox .ant-input-affix-wrapper .ant-input {
  font-family: "Hellix" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #252525 !important;
}

.standard-input::placeholder,
.standard-input input::placeholder,
.standard-searchbox .ant-input-affix-wrapper::placeholder {
  font-family: "Hellix" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #959595 !important;
}

.standard-searchbox-prefix {
  width: 14px !important;
  height: 14px !important;
  color: #959595 !important;
}

.standard-dropdown .ant-select-selector {
  box-sizing: border-box !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
  height: 40px !important;
  padding: 8px 12px !important;
  background: #ffffff !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 8px !important;
}

.standard-dropdown .ant-select-selection-item,
.standard-dropdown .ant-select-selection-placeholder {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}
