.rdw-editor-wrapper {
  border: 1px solid lightgray;
  background-color: white;
}

.rdw-editor-toolbar {
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid lightgray;
}

.rdw-editor-main {
  padding: 6px;
  min-height: 200px;
}

.public-DraftStyleDefault-block {
  margin: 4px 0px;
}

.rdw-link-modal {
  height: fit-content;
}

.editorClassName a,
.form-company-text a,
.form-heading a,
.form-sub-heading a,
.form-consent-text a,
.form-success-message-text a {
  text-decoration: underline;
}

.editorClassName h1,
.form-company-text h1,
.form-heading h1,
.form-sub-heading h1,
.form-consent-text h1,
.form-success-message-text h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.editorClassName h2,
.form-company-text h2,
.form-heading h2,
.form-sub-heading h2,
.form-consent-text h2,
.form-success-message-text h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.editorClassName h3,
.form-company-text h3,
.form-heading h3,
.form-sub-heading h3,
.form-consent-text h3,
.form-success-message-text h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.editorClassName h4,
.form-company-text h4,
.form-heading h4,
.form-sub-heading h4,
.form-consent-text h4,
.form-success-message-text h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.editorClassName h5,
.form-company-text h5,
.form-heading h5,
.form-sub-heading h5,
.form-consent-text h5,
.form-success-message-text h5 {
  display: block;
  font-size: 0.83em;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.editorClassName h6,
.form-company-text h6,
.form-heading h6,
.form-sub-heading h6,
.form-consent-text h6,
.form-success-message-text h6 {
  display: block;
  font-size: 0.67em;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

/* below css is for blustream use not for text editor */
.form-company-text,
.form-heading,
.form-sub-heading,
.form-consent-text,
.form-success-message-text {
  overflow-wrap: anywhere;
}

.form-company-text p:empty,
.form-heading p:empty,
.form-sub-heading p:empty,
.form-consent-text p:empty,
.form-success-message-text p:empty {
  padding: 10px;
}
