.main-product-mapping-container {
  position: relative;
  background-color: white;
  border-radius: 7px;
  min-height: calc(100vh - 112px);
}

.main-product-mapping-container .ant-tabs-tabpane-active {
  min-height: calc(100vh - 320px);
}

.main-product-mapping-container .back-arrow {
  position: absolute;
  top: 22px;
  left: 22px;
  font-size: 20px;
}

.product-mapping-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 7px;
  background-color: #eff4f9;
  margin-bottom: 25px;
  padding-right: 25px;
  background-image: url("./../../assets/Images/curve.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}

.product-mapping-header .left-container {
  display: flex;
  align-items: center;
}

.header-title-container .header-title {
  margin-bottom: 4px;
  font-family: "Hellix";
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  text-transform: capitalize;
  color: #252525;
}

.header-title-container .sub-header-title {
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #252525;
  max-width: 405px;
}

.product-mapping-header .right-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 14px;
}

.product-mapping-header .buttons-container {
  margin: 0px;
  display: flex;
  gap: 15px;
}

.product-mapping-header .last-sync-text {
  display: flex;
  gap: 4px;
}

.product-mapping-header .learn-how-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 25px;
  width: 126px;
  height: 39px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #f37064;
  border: 1.5px solid #f37064;
  border-radius: 100px;
}

.product-mapping-header .sync-now-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  width: 119px;
  height: 39px;
  background: #252525;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: white;
  border-radius: 22px;
}

.product-mapping-header .last-sync-text {
  display: flex;
  align-items: center;
}

.main-product-mapping-container .tab-title {
  font-size: 18px !important;
  font-weight: 600;
  line-height: 22px;
}

.product-mapping-tabs .ant-tabs-nav {
  border-bottom: 2px solid #d9eafb;
}

.shopify-features-container {
  display: flex;
  gap: 12px;
  margin-top: 16px;
  margin-bottom: 10px;
}

.shopify-logo-wrapper,
.blustream-logo-wrapper {
  height: 42px;
  width: 47px;
  background-color: #eff4f9;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shopify-product-type-dropdown {
  width: 233px;
}
.shopify-product-type-dropdown .ant-select-selector {
  height: 42px !important;
  font-size: 18px !important;
  line-height: 22px !important;
  border: 1px solid #252525 !important;
  border-radius: 7px !important;
  color: #252525 !important;
  align-items: center;
}

.shopify-product-type-dropdown .ant-select-arrow {
  color: #252525 !important;
}

.products-type-option {
  font-size: 18px;
  line-height: 22px;
  color: #252525;
}

.shopify-product-search {
  width: calc(100% - 306px);
}

.shopify-features-container .ant-input-affix-wrapper,
.blustream-product-search .ant-input-affix-wrapper {
  height: 42px !important;
  width: 100%;
  background-color: #f5f5f5;
  border: none;
}

.shopify-product-search .ant-input,
.blustream-product-search .ant-input {
  background-color: #f5f5f5;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #252525 !important;
}

.shopify-product-search .ant-input::placeholder,
.blustream-product-search .ant-input::placeholder {
  font-size: 18px !important;
  line-height: 22px !important;
  color: #252525 !important;
}

.shopify-box {
  height: 354px;
  border: 1px solid #d9eafb;
  border-radius: 6px;
  overflow: scroll;
}

.shopify-products-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.initial-content {
  max-width: 408px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-background-img {
  margin-bottom: 16px;
}

.background-text-dark {
  color: #464646;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 8px;
}

.background-text-light {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.blustream-product-search {
  width: calc(100% - 61px);
}

.blustream-header-container {
  display: flex;
  gap: 12px;
  margin-top: 27px;
  margin-bottom: 12px;
}

.blustream-products-container {
  height: 256px;
  border: 1px solid #d9eafb;
  border-radius: 6px;
  overflow: scroll;
}

.blustream-products-container .blustream-text,
.shopify-box .shopify-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #959595;
  margin: 16px auto 12px 18px;
}

.blustream-products-container .title-focus,
.shopify-box .title-focus {
  color: #124173;
}

.blustream-products-container .ant-row {
  margin: 0px 18px !important;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 27px;
}

.map-button,
.mapped-products-button,
.finish-button {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  border-radius: 22px;
  height: 46px;
}

.map-button {
  width: 124px;
  background: #3b84d1;
  color: white;
}

.mapped-products-button {
  width: 237px;
  border: 1px solid #3b84d1;
  color: #3b84d1;
}

.finish-button {
  width: 95px;
}

.mapped-warning-modal,
.unmap-product-modal,
.success-modal {
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.warning-modal-dark-text,
.unmap-product-modal-dark-text,
.success-modal-dark-text {
  margin-top: 22px;
  color: #252525;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  margin-bottom: 0px;
}

.warning-modal-light-text,
.unmap-product-modal-light-text {
  margin-top: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #686868;
  margin-bottom: 0px;
}

.mapped-warning-modal-container,
.unmap-product-modal-container,
.success-modal-container {
  display: flex;
  gap: 20px;
  margin-top: 23px;
}

.mapped-warning-modal-denied-button,
.unmap-product-modal-denied-button {
  color: #252525;
  border: 1px solid #252525;
}

.success-modal-confirm-button {
  width: 171px;
  height: 46px;
  border-radius: 22px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.mapped-product-list-container .drawer-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #252525;
}

.mapped-product-list-container .ant-drawer-header {
  border-bottom: none;
  background-color: #eff4f9;
}

.mapped-product-list-container .ant-drawer-body {
  padding: 20px !important;
}

.platforms-dropdown {
  width: 100%;
  margin-bottom: 20px;
}
.platforms-dropdown .ant-select-selector {
  height: 42px !important;
  font-size: 18px !important;
  line-height: 22px !important;
  border: 1px solid #959595 !important;
  border-radius: 7px !important;
  color: #252525 !important;
  font-weight: 500 !important;
  align-items: center;
}

.platforms-dropdown .ant-select-arrow {
  color: #252525 !important;
}

.platform-option {
  font-size: 18px;
  line-height: 22px;
  color: #252525;
}

.mapped-blustream-product-container {
  display: flex;
  gap: 9px;
  align-items: center;
  margin-bottom: 13px;
}

.blustream-logo {
  height: 19px;
  width: 19px;
}

.mapped-blustream-product-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #124173;
}

.platform-product-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.product-details-wrapper {
  display: flex;
  align-items: center;
  gap: 9px;
}

.platform-product-img {
  height: 36px;
  width: 36px;
  border-radius: 50%;
}

.platform-product-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #252525;
}

.close-icon {
  font-size: 12px;
  color: #959595;
}

.blustream-product-container,
.shopify-product-container {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
}
.shopify-product-container {
  gap: 10px;
}

.blustream-product,
.shopify-product {
  display: flex;
  gap: 9px;
  align-items: center;
}

.blustream-product-image,
.shopify-product-image {
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

.blustream-product-name,
.shopify-product-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #252525;
  padding-right: 10px;
}

.blustream-product-container .ant-radio-inner,
.shopify-product-container .ant-checkbox-inner {
  border-color: #959595 !important ;
  width: 20px;
  height: 20px;
}

.blustream-product-container .ant-radio-inner::after,
.shopify-product-container .ant-checkobx-inner::after {
  position: relative;
  top: 5px;
  left: 5px;
}

.blustream-product-container .ant-radio:hover .ant-radio-inner,
.shopify-product-container .ant-checkbox:hover .ant-checkobx-inner {
  border-color: #959595;
}

.blustream-product-container .ant-radio-checked .ant-radio-inner {
  background-color: #124173;
}

.shopify-product-container .ant-checkbox-checked .ant-checkbox-inner,
.select-all-column .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f37064;
  border: none;
}

.blustream-product-container .ant-radio-checked .ant-radio-inner::after {
  background-color: white;
}

.already-mapped-product {
  width: 13px;
  height: 13px;
}

.select-all-column {
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
}

.select-all-wrapper {
  display: flex;
  gap: 7px;
}

.select-all-text {
  color: #959595;
}

.already-mapped-product-names {
  margin-top: 7px;
  margin-bottom: 0px !important;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #124173;
}

.ant-table-cell::before {
  width: 0px !important;
}

.mapped-products-table,
.tritech-mapped-products-table {
  margin-top: 20px;
  margin-bottom: 10px;
}

.mapped-products-table .ant-table-thead .ant-table-cell {
  background: #eff4f9;
}

.mapped-products-table
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.tritech-mapped-products-table
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.tritech-mapping-list-table
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.mapping-status-modal
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  border-top-left-radius: 8px;
}

.mapped-products-table
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.tritech-mapped-products-table
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.tritech-mapping-list-table
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.mapping-status-modal
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child {
  border-top-right-radius: 8px;
}

.mapped-products-table .ant-table-tbody td,
.tritech-mapped-products-table .ant-table-tbody td {
  border: 1px solid #e8f1fb;
}

.mapped-products-table .ant-pagination-next .ant-pagination-item-link,
.tritech-mapped-products-table .ant-pagination-next .ant-pagination-item-link {
  position: relative;
  border: none;
  width: 55px;
  text-align: right;
  color: #464646;
}
.mapped-products-table .ant-pagination-next .ant-pagination-item-link:hover,
.mapped-products-table
  .ant-pagination-next
  .ant-pagination-item-link::before:hover,
.mapped-products-table .ant-pagination-prev .ant-pagination-item-link,
.mapped-products-table
  .ant-pagination-prev
  .ant-pagination-item-link::before:hover,
.tritech-mapped-products-table
  .ant-pagination-next
  .ant-pagination-item-link:hover,
.tritech-mapped-products-table
  .ant-pagination-next
  .ant-pagination-item-link::before:hover,
.tritech-mapped-products-table .ant-pagination-prev .ant-pagination-item-link,
.tritech-mapped-products-table
  .ant-pagination-prev
  .ant-pagination-item-link::before:hover {
  color: black;
}

.mapped-products-table .ant-pagination-next .ant-pagination-item-link::before,
.tritech-mapped-products-table
  .ant-pagination-next
  .ant-pagination-item-link::before {
  content: "Next";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 11px;
  margin: auto;
  color: #464646;
}

.mapped-products-table .ant-pagination-prev .ant-pagination-item-link,
.tritech-mapped-products-table .ant-pagination-prev .ant-pagination-item-link {
  position: relative;
  border: none;
  width: 55px;
  text-align: left;
  color: #464646;
}

.mapped-products-table .ant-pagination-prev .ant-pagination-item-link::before,
.tritech-mapped-products-table
  .ant-pagination-prev
  .ant-pagination-item-link::before {
  content: "Prev";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 11px;
  margin: auto;
  color: #464646;
}

.mapped-products-table .ant-pagination-options,
.tritech-mapped-products-table .ant-pagination-options {
  position: absolute;
  left: 0;
  margin-left: 15px;
}

.mapped-products-table .ant-table-pagination {
  margin-top: 41px;
  padding: 25px 15px;
  border: 1px solid #e2ebf3;
  border-radius: 11px;
}

.product-mapping-modal .ant-modal-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  background: #eff4f9;
  padding: 12px 24px !important;
  border-radius: 8px 8px 0px 0px;
  text-transform: capitalize;
}

.product-mapping-modal .product-mapping-modal-header-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.product-mapping-modal .ant-modal-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #252525;
}

.product-mapping-modal .ant-modal-close {
  height: 64px;
}

.product-mapping-modal .product-image {
  height: 34px;
  width: 34px;
}

.product-mapping-modal .map-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 116px;
  height: 39px;
  background: #3b84d1;
  border-radius: 22px;
  font-family: "Hellix";
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.product-mapping-modal .ant-modal-close-icon,
.product-mapping-success-modal .ant-modal-close-icon,
.mapping-status-modal .ant-modal-close-icon {
  position: absolute;
  top: 0px;
  right: -30px;
  font-size: 22px;
  color: white;
}

.product-mapping-modal .blustream-product-details {
  display: flex;
  gap: 8px;
  align-items: center;
}

.product-mapping-modal .blustream-logo {
  height: 15px;
  width: 15px;
}

.product-mapping-modal .ant-modal-body {
  padding: 0px;
}

.klaviyo-product-search-container .ant-input-affix-wrapper {
  height: 42px;
  background: #f5f5f5;
  border: none;
}

.klaviyo-product-search-container {
  display: flex;
  gap: 15px;
  align-items: center;
  margin: 16px;
}

.klaviyo-product-search-container .logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47px;
  height: 42px;
  background-color: #f5f5f5;
  border-radius: 6px;
}

.klaviyo-product-search-container .klaviyo-logo {
  width: 27px;
  height: 11px;
}

.klaviyo-product-search-container .ant-input {
  background-color: #f5f5f5;
  font-size: 18px;
}

.klaviyo-product-search-container .select-all {
  width: 100px;
  color: #959595;
}

.klaviyo-product-search-container .ant-checkbox + span {
  padding-right: 0px !important;
  padding-left: 7px !important;
}

.klaviyo-product-search-container .klaviyo-search .ant-input::placeholder {
  font-family: "Hellix";
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
}

.product-mapping-modal .product-type-title {
  font-family: "Hellix";
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #252525;
  padding: 8px 0px 7px 24px;
  margin-bottom: 10px;
  background-color: #f5f5f5;
}

.product-mapping-success-modal .product-mapping-success-modal-content,
.mapping-status-modal .mapping-status-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-mapping-success-modal .success-text {
  margin: 0px;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #25b291;
}

.product-mapping-success-modal .success-icon {
  height: 26px;
  width: 26px;
}

.product-mapping-success-modal .product-name-container {
  display: flex;
  gap: 7px;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 20px;
}

.product-mapping-success-modal .product-name {
  font-family: "Hellix";
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
  color: #252525;
}

.product-mapping-success-modal .blustream-logo,
.mapping-status-modal .blustream-logo {
  height: 16px;
  width: 16px;
}

.plateforms-mapped-products .ant-table-row,
.plateforms-mapped-products thead > tr,
.plateforms-mapped-products thead,
.plateforms-mapped-products .ant-table,
.tritech-mapping-list-table .ant-table-row,
.tritech-mapping-list-table thead > tr,
.tritech-mapping-list-table thead,
.tritech-mapping-list-table .ant-table {
  background-color: white !important;
}

.plateforms-mapped-products thead > tr > th,
.tritech-mapping-list-table thead > tr > th {
  background-color: #eff4f9 !important;
  border: none !important;
}

.plateforms-mapped-products tbody > tr.ant-table-row:hover > td,
.tritech-mapping-list-table tbody > tr.ant-table-row:hover > td {
  background-color: white !important;
}

.plateforms-mapped-products tbody .ant-table-cell,
.tritech-mapping-list-table tbody .ant-table-cell {
  border: 1px solid #e8f1fb !important;
  color: #252525;
}

.mapping-status-modal .product-name-container {
  display: flex;
  gap: 7px;
  align-items: center;
  margin: 20px 0px;
}

.mapping-status-modal .product-name {
  font-family: "Hellix";
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #252525;
}

.mapping-status-modal .plateforms-mapped-products {
  width: 100%;
}

.mapping-status-modal
  .ant-table-container
  table
  > tbody
  > tr:last-child
  td:last-child {
  border-bottom-right-radius: 8px !important;
}

.mapping-status-modal
  .ant-table-container
  table
  > tbody
  > tr:last-child
  td:first-child {
  border-bottom-left-radius: 8px !important;
}

.mapping-status-modal-content .go-back-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 25px;
  width: 111px;
  height: 40px;
  border: 1px solid #252525;
  border-radius: 100px;
  font-family: "Hellix";
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #252525;
}

.mapping-status-modal-content .map-now-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 104px;
  height: 40px;
  background: #3b84d1;
  border-radius: 100px;
  font-family: "Hellix";
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
}
.info-icon {
  color: #959595;
  stroke: #959595;
  stroke-width: 2%;
}
.pen-pointer {
  cursor: pointer;
}
.info-msg {
  color: #3b84d1;
  font-style: italic;
  font-weight: 600;
}
.react-joyride__tooltip {
  padding: 0px !important;
}

.react-joyride__tooltip > div:nth-child(1) > div {
  padding: 14px !important;
}

.react-joyride__tooltip > div:nth-child(2) {
  display: none !important;
}

.skip-btn,
.skip-btn:hover {
  background: transparent;
  border: none;
  color: #54708e;
  padding: 0px;
  font-size: 14px;
  font-weight: 600;
}

.tour-steps-content .ant-progress-show-info .ant-progress-outer {
  margin: 0px !important;
  padding: 0px !important;
}

.tour-steps-content .ant-progress {
  height: 6px !important;
}

.progrees-bar .ant-progress-bg {
  background-color: #f37064 !important;
}
.progrees-bar .ant-progress-text {
  display: none !important;
}
.text-center {
  text-align: center !important;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #a9bdd2;
}

.shopify-product-container .ant-checkbox-checked .ant-checkbox-inner::after {
  left: 6px;
}

.shopify-products-main-container {
  max-height: 500px !important;
  overflow: scroll !important;
  border-radius: 8px !important;
}

.disable-pointer-events {
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.existing-contact-select .ant-form-item-label > label {
  width: 100%;
}

/* ---------------------tritech product mapping------------------------ */

.tritech-mapped-products-table {
  margin-top: 20px;
}

.tritech-mapped-products-table .tritech-table-header-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.tritech-table-blustream-header-logo {
  height: 18px;
  width: 18px;
}

.tritech-table-blustream-header-text,
.tritech-table-tritech-header-text {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 134.19%;
  text-transform: capitalize;
  color: #124173;
}

.tritech-table-tritech-header-logo {
  height: 20px;
  width: 20px;
}

.tritech-mapped-products-table
  .ant-table-container
  table
  > thead
  > tr:first-child
  th {
  padding: 13px;
  background-color: #eff4f9;
}

.tritech-mapped-products-table .ant-table-pagination {
  margin-top: 0px;
  padding: 25px 15px;
  border: 1px solid #e2ebf3;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
}

.tritech-mapping-modal {
  width: fit-content !important;
}

.tritech-mapping-modal .ant-modal-body {
  padding: 0px;
}

.tritech-mapping-modal .ant-modal-close-icon {
  position: absolute;
  left: 7px;
  font-size: 22px;
  color: white;
}

.tritech-mapping-modal .ant-modal-close-x {
  position: absolute;
}

.tritech-mapping-modal .tritech-mapping-modal-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tritech-mapping-modal .ant-modal-header {
  position: relative;
  background: #eff4f9;
  padding: 12px 24px !important;
  border-radius: 8px 8px 0px 0px;
  text-transform: capitalize;
}

.tritech-mapping-modal .blustream-product-details {
  display: flex;
  gap: 8px;
  align-items: center;
}

.tritech-mapping-modal .product-image {
  height: 34px;
  width: 34px;
}

.tritech-mapping-modal .product-name {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #252525;
}

.tritech-mapping-modal .blustream-logo {
  height: 15px;
  width: 15px;
}

.tritech-mapping-modal .tritech-mapping-modal-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tritech-mapping-modal .ant-input-number {
  min-width: 212px;
}

.tritech-mapping-modal .ant-input-number-input {
  padding: 0px;
}

.tritech-mapping-modal .ant-form-item {
  margin: 0px;
}

.tritech-mapping-modal .ant-form-item-label {
  padding-bottom: 6px;
  line-height: 1;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #252525;
}

.tritech-mapping-list-table {
  max-width: 528px;
  min-width: calc(100% - 50px);
  margin-bottom: 20px;
}

.tritech-mapping-list-table
  .ant-table-container
  table
  > thead
  > tr:first-child
  th {
  padding: 12px 10px;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #252525;
}

.tritech-mapping-list-table
  .ant-table-container
  table
  > tbody
  > tr:last-child
  td:first-child {
  border-bottom-left-radius: 8px;
}
.tritech-mapping-list-table
  .ant-table-container
  table
  > tbody
  > tr:last-child
  td:last-child {
  border-bottom-right-radius: 8px;
}

.tritech-category-wrapper,
.tritech-sub-category-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tritech-category-title,
.tritech-sub-category-title {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #929292;
}

.tritech-category-number,
.tritech-sub-category-number {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background: #eff4f9;
  border-radius: 50%;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #252525;
}

.shopify-mapping-placeholder {
  height: calc(100vh - 300px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.mapping-placeholder-text {
  max-width: 465px;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #959595;
  margin-bottom: 0px;
}

.product-varient-details {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #959595;
}
