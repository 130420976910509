/* mobile screen  */
@media screen and (min-width: 320px) and (max-width: 780px) {
  main.ant-layout-content {
    padding: 15px 10px !important;
  }
  .view-btns {
    width: 100%;
    justify-content: center;
  }
  .searchNames {
    width: 100% !important;
    margin: 10px 0 !important;
  }
  .workFlow .wrapper .w-50 {
    width: 100% !important;
    margin-left: 0 !important;
    margin-bottom: 9px !important;
  }
  .listView .listHeader h3 {
    width: 100%;
    text-align: center;
    margin-bottom: 10px !important;
  }
  .listView .buttonGroup {
    display: flex;
    margin-bottom: 10px !important;
    justify-content: center;
    width: 100%;
  }
  .listView .boxHeader {
    flex-direction: column;
  }
  .listView .boxHeader .flex {
    justify-content: flex-end;
  }
  .listView .listHeader,
  .listView .listContent {
    width: 90% !important;
  }
  table {
    table-layout: unset !important;
  }
  .ant-table-content {
    overflow: scroll !important;
  }
  .ant-table th.ant-table-cell {
    width: 200px !important;
  }
  .w-100 {
    width: 100% !important;
  }
  .tableHeader {
    position: relative;
  }
  .tableHeader .view-btns {
    width: auto;
    position: absolute;
    z-index: 999;
    top: 9px;
    right: 31px;
  }
  .tableHeader .flexBox {
    margin: 10px 0;
  }

  .selectTab {
    margin-top: 10px;
  }
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
  .tableView td.ant-table-cell {
    min-width: 150px !important;
  }
  .responsiveRow {
    display: grid !important;
    grid-template-columns: 30% 30% 30%;
    row-gap: 15px;
  }
  .ant-table-wrapper {
    overflow-y: scroll !important;
  }
  .ant-tabs.ant-tabs-top.ant-tabs-centered.setting-table {
    padding: 5px !important;
  }
  .responsiveSlider {
    padding: 0px !important;
    border: none !important;
  }
  .responsiveSlider div:nth-child(1) {
    padding: 0 0px !important;
  }

  .ant-slider-mark-text {
    font-size: 8px !important;
    margin-top: 2px !important;
  }
  .responsiveText {
    font-size: 10px !important;
  }
  .responsiveImg {
    height: 25px !important;
  }
  .plan-details-title {
    font-size: 18px !important;
    padding-top: 9px !important;
  }
  .entrepreneur-use-to-item {
    font-size: 13px !important;
  }
  .plan-details-text {
    font-size: 16px !important;
    padding-top: 5px !important;
  }
  .select-plan-btn {
    margin-top: 0px !important;
  }

  .divider-col {
    padding: 0px !important;
  }
  .slider-wrapper {
    gap: 5px !important;
  }
  .slider-wrapper .standard {
    width: 24% !important;
  }
  .row-slider {
    display: flex !important;
    justify-content: center;
  }

  .ant-layout-sider-collapsed {
    flex: 0 0 0px;
    max-width: 80px !important;
    min-width: 80px !important;
    width: 0px;
  }
  .wrapperBox-header .ant-page-header-heading-left {
    flex-wrap: wrap !important;
  }
  .wrapperBox-header .ant-page-header-heading-sub-title {
    margin: 10px 0 !important;
  }
  .wrapperBox-header .ant-page-header-heading-extra {
    width: 100%;
    display: flex;
  }
  .wrapperBox-header .ant-page-header-heading-extra button {
    width: 45% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wrapperBox-header .ant-page-header-heading-extra button .text {
    display: flex;
  }
  .form-body {
    min-height: auto !important;
  }
  .form-body .form {
    overflow: scroll;
    flex-wrap: nowrap;
  }
  .form-body .form .ant-col-order-1 {
    min-width: 400px !important;
  }
  .form-body .form .ant-col-order-2 {
    min-width: 250px !important;
  }
  .ant-tabs-nav-list .ant-tabs-tab {
    margin-left: 5px !important;
  }

  .clear-btn {
    margin-bottom: 10px !important;
  }
}

/* ipad  */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .ant-table-wrapper {
    overflow-y: scroll !important;
  }
  .poweredby-logo {
    max-width: 100% !important;
    height: auto !important;
  }
}

@media (max-width: 992px) {
  .tabs-container {
    margin-top: 20px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .form-body .form {
    overflow: scroll;
    flex-wrap: nowrap;
  }
  .form-body .form .ant-col-order-1 {
    min-width: 400px !important;
  }
  .form-body .form .ant-col-order-2 {
    min-width: 250px !important;
  }
  .form-body {
    min-height: auto !important;
  }
  .ant-tabs-nav-list .ant-tabs-tab {
    margin-left: 15px !important;
  }
  .ant-tabs-nav-list + .ant-tabs-tab {
    margin-left: 0px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .layout-wrapper {
    display: grid !important;
    grid-template-columns: 48% 48% !important;
    gap: 10px !important;
  }
  .form-body {
    min-height: auto !important;
  }
  .signup-form-container,
  .login-form-container,
  .confirm-password-form-container,
  .confirm-email-form-container {
    width: 437px !important;
  }
  .reset-password-form-container,
  .set-new-password-form-container {
    width: 390px !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .layout-wrapper {
    display: grid !important;
    grid-template-columns: 100% !important;
    gap: 10px !important;
  }
}

@media only screen and (max-width: 576px) {
  .all-contact-heading {
    width: 100%;
  }
  .contactFlexBox {
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }

  .tableFlexBox {
    flex-direction: column;
    justify-content: flex-start;
  }

  .flexbox-container {
    width: 100%;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: space-between;
  }
  .contactFlexBox div:nth-child(1) {
    margin-right: 0px !important;
  }

  .flexCol {
    flex-direction: column;
    width: 100%;
  }
  .flexCol .ant-space-item {
    width: 100%;
  }

  .w100 {
    width: 100%;
  }

  .mb-2 {
    margin-bottom: 32px;
  }

  .company-description .ant-descriptions-row {
    display: flex;
    flex-direction: column;
  }

  .product-detail-container {
    flex-direction: column;
    width: 100%;
  }
  .signup-form-container,
  .login-form-container,
  .confirm-password-form-container,
  .confirm-email-form-container {
    width: 100vw !important;
  }
}

@media only screen and (max-width: 420px) {
  .reset-password-form-container,
  .set-new-password-form-container {
    width: 100vw !important;
  }
}

.blustream-sider-menu .anticon {
  vertical-align: -webkit-baseline-middle;
}

/* .blustream-sider-menu {
  overflow-y: scroll !important;
} */
