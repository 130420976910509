.organization-request-access-main-container {
  background-color: white;
  margin-bottom: 21px;
  border-radius: 28px;
}

.organization-request-access-main-container .skip-button {
  position: absolute;
  right: 25px;
  top: 25px;
}

.org-request-success-message-container,
.org-request-denied-message-container {
  background-color: #e4f5f1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  padding: 12px;
}

.org-request-denied-message-container {
  background-color: #ffe9e9;
}

.org-request-success-message-container .success-icon,
.org-request-denied-message-container .denied-icon {
  width: 17px;
  height: 16px;
}

.org-request-success-message-container .org-request-success-message,
.org-request-denied-message-container .org-request-denied-message {
  margin-bottom: 0px;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.organization-request-access-content-row {
  justify-content: center;
}

.organization-request-access-content-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.organization-request-access-content-col .lock-image {
  width: 68px;
  height: 104px;
  margin-top: 50px;
}

.organization-request-access-content-col .simple-text {
  max-width: 237px;
  color: #252525;
  text-align: center;
  font-family: Hellix;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 20px;
}

.organization-request-access-content-col .organization-name {
  color: #f37064;
  text-align: center;
  font-family: Hellix;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin: 15px 0px;
}

.organization-request-access-content-col .reason-txt,
.organization-request-access-content-col .linked-store-name {
  color: #252525;
  text-align: center;
  font-family: Hellix;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.organization-request-access-content-col .reason-txt {
  margin-bottom: 20px;
  max-width: 348px;
}

.organization-request-access-content-col .linked-store-name {
  color: #124173;
}

.organization-request-access-content-col .request-access-button {
  margin-bottom: 50px;
}

.organization-request-access-content-col .request-success-text-container,
.organization-request-access-content-col .request-denied-text-container,
.organization-request-access-content-col .request-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.organization-request-access-content-col .org-request-success-message,
.organization-request-access-content-col .org-request-denied-message {
  color: #25b291;
  font-family: Hellix;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.organization-request-access-content-col .org-request-denied-message {
  color: #ff5a5a;
}

.request-success-text-container .notify-text,
.request-denied-text-container .notify-text {
  margin: 10px 0px 30px;
  color: #959595;
  text-align: center;
  font-family: Hellix;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.organization-request-access-content-col .new-organization-text-wrapper {
  display: flex;
  gap: 32px;
  align-items: center;
  border-radius: 4px;
  background: #eff4f9;
  padding: 15px;
  margin-bottom: 50px;
}

.new-organization-text-wrapper .new-organization-text {
  max-width: 276px;
  color: #252525;
  font-family: Hellix;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 0px;
}
