.shopify-product-fetch-loader-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 120px);
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.shopify-steppers-main-col .stepper-logout {
  position: absolute;
  right: 25px;
  top: 25px;
  z-index: 10;
}

.shopify-steppers-main-col {
  background-color: white;
  margin-bottom: 21px;
  border-radius: 28px;
}

.shopify-steppers-sub-col {
  background-color: white;
  margin-bottom: 41px;
  border-radius: 28px;
}
