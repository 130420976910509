.link-store-main-container {
  background-color: white;
  margin-bottom: 21px;
  border-radius: 28px;
}

.link-store-main-container .skip-button {
  position: absolute;
  right: 25px;
  top: 25px;
}

.connect-organization-content-row {
  margin-top: 37px;
}

.connect-organization-content-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.connect-organization-content-col .welcome-text {
  color: #252525;
  font-family: Hellix;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 0px;
}

.connect-organization-content-col .help-text {
  color: #252525;
  text-align: center;
  font-family: Hellix;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-top: 20px;
  margin-bottom: 0px;
}

.connect-organization-content-col .store-name {
  color: #25b291;
  text-align: center;
  font-family: Hellix;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin-top: 20px;
  margin-bottom: 0px;
}

.connect-organization-content-col .empty-organization-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.connect-organization-content-col .broken-chain-image {
  height: 66px;
  width: 69px;
  margin-top: 61px;
}

.connect-organization-content-col .placeholder-text {
  color: #959595;
  text-align: center;
  font-family: Hellix;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  margin: 25px 0px;
}

.connect-organization-content-col .link-store-buttons-container {
  display: flex;
  gap: 14px;
  margin-bottom: 30px;
}

.organization-boundary-above,
.organization-boundary-below {
  border: 0.5px solid #d9d9d9;
  width: "100%";
  margin: 16px 0px;
}

.organization-boundary-above {
  margin-top: 31px;
}

.organization-boundary-below {
  margin-bottom: 8px;
}

.organization-note-text {
  color: #959595;
  text-align: right;
  font-family: Hellix;
  font-size: 14px;
  font-weight: 500;
}

.disable-store-note-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 50px;
}

.create-org-modal .ant-modal-close {
  visibility: hidden;
}

.create-org-modal .close-button {
  font-size: 22px;
  position: absolute;
  left: 65px;
  color: white;
  cursor: pointer;
  visibility: visible;
}

.create-org-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create-org-modal-body .modal-title {
  color: #f5786d;
  font-family: Hellix;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 6px;
}

.create-org-modal-body .instruction-text {
  color: #252525;
  text-align: center;
  font-family: Hellix;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin: 0px auto 24px !important;
}

.create-org-for-shopify-form .company-name {
  margin-bottom: 10px;
}

.create-org-for-shopify-form .company-name label,
.create-org-for-shopify-form .company-url label {
  color: #252525;
  font-family: Hellix;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.create-org-for-shopify-form .submit-button {
  margin-bottom: 8px;
}

.connect-organization-content-row .organizations-list,
.connect-organization-content-row .organizations-list .ant-radio-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.connect-organization-content-row .organizations-list .ant-radio-wrapper,
.linked-organization-container {
  color: #252525;
  font-family: Hellix;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.linked-organization-container {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #959595;
}

.disconnect-shopify-steps-tooltip {
  color: #595959;
}

.disconnect-shopify-steps-tooltip .tooltip-title {
  font-weight: bold;
  margin-bottom: 30px;
}
