/* Header */
.editor-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 21px; 
    min-width: 100%; 
    background: rgb(239, 244, 249);
}

.editor-header-left {
    text-align: left;
}

.editor-header-left h2 {
    margin: 0;
}

.editor-header-left .subheading {
    margin: 0;
    font-size: 14px;
    color: #1f1f1f;
}

.editor-header-right {
    display: flex;
    align-items: center;
}

.editor-header-right button {
    margin-left: 10px;
    border-radius: 5px;
}

.editor-header-right .save-button {
    width: 80px;
    border: 0;
    color: #fff;
    background-color: #c7c7c7;
    height: 33px;
    font-weight: regular-bold;
}

.editor-header-right .save-button:not(:disabled):hover {
    background-color: #0056b3; 
    cursor: pointer;
}

.editor-header-right .save-button:disabled {
    background-color: #c7c7c7;
    cursor: not-allowed;
}

.editor-header-right .preview-button {
    width: 100px;
    border: 1px solid #124173;
    color: #124173;
    background-color: #fff;
    height: 33px;
    font-weight: regular-bold;
}

.main-container {
    display: flex;
    flex:1; 
    height: 100vh;
    background: rgb(239, 244, 249);
}

.editor-main-container {
    display: flex;
    flex: 1;
    margin-right: 0;
    background: rgb(239, 244, 249);
}

/* TinyMCE Editor */
.editor-container {
    background-color: #fff;
    border: none !important;
    width: 100% !important;
    background: rgb(239, 244, 249);
}

/* Template Section */
.templates-list-header-container {
    padding-top: 0px;
    margin: 0;
    height: fit-content;
    width: 33%; 
    background: rgb(239, 244, 249);
}

.tabs-header {
    display: flex;
    justify-content: space-between;
    background-color: #cde1f3;
    align-items: center;
    height: 100%;
    position: relative;
    left: 10px;
}

.tab-icon {
    width: 20px;
    height: 20px;
}

.tab-label {
    font-size: 14px;
    font-weight: normal;
}

.tab.active .tab-label {
    font-weight: bold;
}

.template-list-container {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 1px;
    background: rgb(239, 244, 249);
    position: relative;
    left: 10px;
}

.template-card {
    display: flex;
    flex-direction: row; 
    border-radius: 8px;
    padding: 5px;
    margin-bottom: 10px;
    background-color: #fff;
    display: flex;
    align-items: stretch;
    max-height: 200px;
    min-height: 200px; 
    overflow: hidden;
}

.template-card.selected {
    border: 2px solid #124173;
}

.template-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    min-height: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0;
    padding-left: 10px;;
} 

 .template-title {
    font-size: 16px;
    font-weight: bold;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    
}

.template-body {
    font-size: 14px;
    color: black;
    overflow: hidden;
    flex-grow: unset; 
    margin:0;
    padding: 0;
    min-height:83%;
    max-height: 83%;
    width: 100%!important;
} 

 .template-preview-container {
    margin: 0 5% 0 5%;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    max-width: 600px;
}

/* Upload Asset Modal */
.modal {
    position: fixed;
    top:17%;
    left: 25%;
    width: 80%;
    max-width: 600px;
    background: white;
    padding: 0;
    z-index: 1000;
    border-radius: 10px;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  ul.assets-list {
    max-height: 400px;
    overflow-y: scroll;
    background-color: inherit;
    padding: 0;
    background-color: white;
  }

  li.asset-item{
    display: flex;
    align-items: center;
    background-color: inherit;
    margin-bottom: 10px;
    max-height: '120px';
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #ccc; 
    padding-bottom: 10px; 
  }
  
  .asset-image {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .delete-button {
    margin-right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: grey;
  }

  .asset-url {
    flex-grow: 1;
    margin-right: 10px;
  }
  
  .copy-button {
    background-color: #124173;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .copy-button:hover, .delete-button:hover {
    background-color: white;
    color: #124173;
    border: 1px solid #124173;
  }

  .assets-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.assets-header h2 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}

.assets-header .sort-button {
    background-color: #124173;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
}

.assets-header .sort-button:hover {
    background-color: white;
    color: #124173;
    border: 1px solid #124173;
}

/* Uploaded Asset */
.uploaded-asset {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.uploaded-asset .asset-url {
  flex-grow: 1;
  margin-right: 10px;
}

.uploaded-asset .copy-button {
  background-color: #124173;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}

.uploaded-asset .copy-button:hover {
  background-color: #0056b3;
}

.ant-spin-spinning {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: 1000 !important;
  }

@media screen and (max-width: 600px) {
    .main-container {
        flex-direction: column;
        height: 200px;
    }

    .editor-container {
        width: 100%;
        min-height: 50vh;
    }

    .templates-list-header-container {
        width: 100%;
    }

    .template-card {
        max-height: 100%;
        min-height: 100%;
    }

    .template-body {
        min-height: 80%;
        max-height: 100%;
    }

    .template-preview-container {
        display: none;
    }
}