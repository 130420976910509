.contact-us-container {
  display: flex;
  gap: 13px;
}

.contact-us-image-container {
  border-radius: 50%;
  background-color: #f4faff;
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.contact-us-text-container {
  display: flex;
  flex-direction: column;
  gap: 11px;
  justify-content: center;
}

.support-form .ant-form-item-label label {
  font-size: 16px;
}
